@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-[#0A0A0A] text-white font-ibm-plex-mono;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Add this to the end of your existing CSS */

.card-container.flipped .card {
    transform: rotateY(180deg);
}
